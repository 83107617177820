import {
    breakpointDesktop,
    IconButton,
    InfoCard,
    Subtitle,
} from '@moller/design-system';
import i18next from 'i18next';
import { styled } from 'styled-components';
import { Link } from 'react-router';

const Card = styled(InfoCard)`
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    border-color: var(--moller-color-outline);

    @media screen and (min-width: ${breakpointDesktop}) {
        flex-direction: row;
        padding: var(--moller-spacing-xxl);
    }
`;

const Content = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
`;

const BoxTitle = styled(Subtitle)`
    margin-bottom: var(--moller-spacing-xxs);
`;

const Description = styled.p`
    margin-bottom: var(--moller-spacing-s);

    @media screen and (min-width: ${breakpointDesktop}) {
        margin-bottom: 0;
    }
`;

export const NewCarBox = ({ className }: { className?: string }) => {
    return (
        <Card className={className} transparent>
            <Content>
                <BoxTitle>{i18next.t('newCarTitle')}</BoxTitle>
                <Description>{i18next.t('newCarInfo')}</Description>
            </Content>
            <IconButton
                icon="arrow_forward"
                // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                label={i18next.t('goToBruktbil')}
                showLabel="left"
                $as={Link}
                to="https://www.bruktbil.no/moller-bil"
            >
                {i18next.t('goToBruktbil')}
            </IconButton>
        </Card>
    );
};
