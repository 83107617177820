import { styled } from 'styled-components';
import i18next from 'i18next';
import { PriceEstimateModel } from '@/types/PriceEstimateModel';
import { numberFormat } from '@/utils/numberFormat';

export const CardTitle = styled.h2`
    font-weight: 500;
    font-size: var(--moller-typeface-size-xs);
    color: var(--moller-palette-info-60);
    text-transform: uppercase;
    margin: 0 0 var(--moller-spacing-xxs);
`;

export const CardSubTitlePrefix = styled.p`
    font-size: var(--moller-typeface-size-base);
    font-weight: 400;
`;

export const CardSubTitle = styled.h3`
    font-size: var(--moller-typeface-size-l);
    font-weight: 500;
    line-height: var(--moller-typeface-line-height);
    margin: 0 0 var(--moller-spacing-xxs);
`;

const InfoText = styled.p`
    line-height: var(--moller-typeface-line-height);
    padding-bottom: var(--moller-spacing-xxs);
`;

interface PriceEstimateContentProps {
    priceEstimate: PriceEstimateModel;
}

export const PriceEstimateContent = ({
    priceEstimate,
}: PriceEstimateContentProps) => {
    return (
        <>
            <CardTitle>{i18next.t('weThinkWeCanOffer')}</CardTitle>
            <CardSubTitle>{`${numberFormat(
                priceEstimate?.offerMinPrice,
            )} - ${numberFormat(
                priceEstimate?.offerMaxPrice,
            )} kr`}</CardSubTitle>
            <InfoText>{i18next.t('priceEstimateInfoText1')}</InfoText>
            <InfoText>{i18next.t('priceEstimateInfoText2')}</InfoText>
        </>
    );
};
