import { CalculationModel } from '@/types/CalculationModel';
import { css, styled } from 'styled-components';
import { LabelText, Subtitle } from '@moller/design-system';
import i18next from 'i18next';

const PriceGroup = styled.div`
    display: flex;
    justify-content: space-between;
`;

const Label = styled(LabelText)<{ $isSalesPriceComponent?: boolean }>`
    ${(props) => css`
        flex-wrap: wrap;
        max-width: 50%;
        color: var(--moller-color-on-primary);
        font-size: var(--moller-typeface-size-s);
        font-weight: ${props.$isSalesPriceComponent ? 500 : 400};
    `}
`;

const Price = styled(Subtitle)<{ $isSalesPriceComponent?: boolean }>`
    ${(props) => css`
        color: var(--moller-color-on-primary);
        font-weight: 500;
        font-size: ${props.$isSalesPriceComponent
            ? '18px'
            : 'var(--moller-typeface-size-base)'};
    `}
`;

const Content = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: var(--moller-spacing-s);
    margin-bottom: var(--moller-spacing-s);
`;

const formatPrice = (price: number) => price?.toLocaleString('no-NO') || '0';

const calculatePreparationTotal = (calculation: CalculationModel) => {
    return calculation.warranty + calculation.preparation + calculation.sales;
};

const calculateProfit = (
    calculation: CalculationModel,
    purchasePrice: number,
) => {
    return (
        calculation.marketPrice -
        purchasePrice -
        calculation.reRegistration -
        calculatePreparationTotal(calculation)
    );
};

interface PriceBreakdownProps {
    calculation: CalculationModel;
    purchasePrice: number;
}

export const PriceBreakdown = ({
    calculation,
    purchasePrice,
}: PriceBreakdownProps) => {
    return (
        <Content>
            <PriceGroup>
                <Label>{i18next.t('reregistration')}</Label>
                <Price>
                    {formatPrice(calculation.reRegistration)}{' '}
                    {i18next.t('priceSuffix')}
                </Price>
            </PriceGroup>
            <PriceGroup>
                <Label>{i18next.t('costOfSales')}</Label>
                <Price>
                    {formatPrice(calculatePreparationTotal(calculation))}{' '}
                    {i18next.t('priceSuffix')}
                </Price>
            </PriceGroup>
            <PriceGroup>
                <Label>{i18next.t('ourProfit')}</Label>
                <Price>
                    {formatPrice(calculateProfit(calculation, purchasePrice))}{' '}
                    {i18next.t('priceSuffix')}
                </Price>
            </PriceGroup>
            <PriceGroup>
                <Label $isSalesPriceComponent>{i18next.t('salesPrice')}</Label>
                <Price $isSalesPriceComponent>
                    {i18next.t('pricePrefix')}{' '}
                    {formatPrice(calculation.marketPrice)}{' '}
                    {i18next.t('priceSuffix')}
                </Price>
            </PriceGroup>
        </Content>
    );
};
