import { css, styled } from 'styled-components';
import { Icon } from '@moller/design-system';
import i18next from 'i18next';
import { PriceEstimateCustomerResponse } from '@/types/PriceEstimateModel';
import parse from 'html-react-parser';
import { PriceOfferCustomerResponse } from '@/types/OfferModel';
import { usePurchaserEmail } from '@/utils/usePurchaserEmail';

const Response = styled.div<{ $hasAccepted: boolean }>`
    ${(props) => css`
        display: flex;
        background-color: var(--moller-color-surface);
        width: 100%;
        justify-content: center;
        align-items: center;
        margin-top: var(--moller-spacing-l);
        border-radius: var(--moller-border-radius-x-large);

        svg path {
            fill: ${props.$hasAccepted
                ? 'var(--moller-color-success)'
                : 'var(--moller-color-error)'};
        }

        p {
            padding: var(--moller-spacing-xxs) 0 var(--moller-spacing-xxs)
                var(--moller-spacing-base);

            color: ${props.$hasAccepted
                ? 'var(--moller-color-success)'
                : 'var(--moller-color-error)'};
        }
    `}
`;

const Info = styled.p`
    margin-top: var(--moller-spacing-xxs);
    font-size: var(--moller-typeface-size-xs);
    text-align: center;
    color: var(--moller-palette-white);
    line-height: var(--moller-typeface-line-height);

    a {
        color: var(--moller-palette-white) !important;
    }
`;

interface CustomerResponseProps {
    customerResponse:
        | PriceEstimateCustomerResponse
        | PriceOfferCustomerResponse;
    showInfo?: boolean;
    className?: string;
}

export const CustomerResponse = ({
    customerResponse,
    showInfo = true,
    className,
}: CustomerResponseProps) => {
    const isRenewed = customerResponse === 'RenewOffer';
    const isPositiveResponse =
        customerResponse === 'Interested' ||
        customerResponse === 'Accepted' ||
        isRenewed;
    const { email } = usePurchaserEmail();

    return (
        <div className={className}>
            <Response $hasAccepted={isPositiveResponse}>
                <Icon icon={isPositiveResponse ? 'check' : 'close'} />
                <p>{i18next.t(customerResponse)}</p>
            </Response>
            {showInfo && (
                <Info>
                    {parse(
                        i18next.t(
                            isRenewed
                                ? 'renewOfferInfo'
                                : isPositiveResponse
                                  ? 'acceptedInfo'
                                  : 'declinedInfo',
                            {
                                email: email,
                            },
                        ),
                    )}
                </Info>
            )}
        </div>
    );
};
