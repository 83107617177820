import { LinkCard } from '@moller/design-system';
import { PATH_EXPECTATIONS } from '@/utils/url/routes';
import { useParams, Link } from 'react-router';
import { useGetMyPageValuation } from '../queries/useGetMyPageValuation';
import i18next from 'i18next';
import { datadogRum } from '@datadog/browser-rum';
import { useEffect } from 'react';
import { LinkCardContent } from '@/components/LinkCardContent';

export const ExpectationsCard = ({ className }: { className?: string }) => {
    const { id } = useParams<{ id: string }>();
    const { data: valuation } = useGetMyPageValuation(id);
    const expectationsIsSent = !!valuation?.expectations?.similarCarUrl;

    useEffect(() => {
        if (valuation && !expectationsIsSent) {
            datadogRum.addAction('Add expectations card visible');
        }
    }, [valuation, expectationsIsSent]);

    if (valuation) {
        return (
            <LinkCard
                className={className}
                key={valuation.valuationId}
                $as={Link}
                to={`${PATH_EXPECTATIONS}/${valuation.valuationId}`}
            >
                <LinkCardContent
                    title={i18next.t(
                        expectationsIsSent
                            ? 'expectationsTitle'
                            : 'addExpectationsTitle',
                    )}
                    description={i18next.t('expectationsDescription')}
                />
            </LinkCard>
        );
    } else return <></>;
};
