import { Page } from '@/components/Page';
import i18next from 'i18next';
import { useParams, Link, useNavigate } from 'react-router';
import { PATH_MY_PAGE } from '@/utils/url/routes';
import { Banner } from '../my-page-contract-signed/MyPageContractSigned';
import { useSendContractSigningStatus } from '../my-page-contract-signed/queries/useSendContractSigningStatus';
import { useEffect } from 'react';
import { LoadingOverlay } from '@/components/loading/LoadingOverlay';
import { GeneralErrorAlert } from '@/components/GeneralErrorAlert';
import { PageHeader } from '@/components/PageHeader';
import { PrimaryButton } from '@moller/design-system';

export const MyPageContractDeclined = () => {
    const { id } = useParams<{ id: string }>();
    const sendContractSigningStatus = useSendContractSigningStatus(id);
    const { isError, isLoading, isIdle } = sendContractSigningStatus;
    const navigate = useNavigate();

    useEffect(() => {
        if (!isIdle) return;
        if (id) sendContractSigningStatus.mutate('rejected');
    }, [id, isIdle, sendContractSigningStatus]);

    return (
        <>
            {id && (
                <PageHeader
                    titleTextKey="signContract"
                    backButtonOnClick={() =>
                        void navigate(`${PATH_MY_PAGE}/${id}`)
                    }
                    backButtonTextKey="toFrontpage"
                />
            )}
            <Page>
                {isIdle || isLoading ? (
                    <LoadingOverlay delayMillis={0} />
                ) : isError ? (
                    <GeneralErrorAlert />
                ) : (
                    <>
                        <Banner
                            type="error"
                            heading={i18next.t('declinedContractTitle')}
                            message={i18next.t('declinedContractMessage')}
                        />
                        <PrimaryButton
                            style={{ alignSelf: 'flex-end' }}
                            $as={Link}
                            to={`${PATH_MY_PAGE}/${id || ''}`}
                        >
                            {i18next.t('goToFrontpage')}
                        </PrimaryButton>
                    </>
                )}
            </Page>
        </>
    );
};
