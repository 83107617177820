import { styled } from 'styled-components';
import { PageHeader } from '@/components/PageHeader';
import { Page as PageComponent } from '../../components/Page';
import { useParams, useNavigate } from 'react-router';
import { useGetMyPageValuation } from '../my-page/queries/useGetMyPageValuation';
import { PATH_MY_PAGE } from '@/utils/url/routes';
import { PriceEstimate } from '../my-page/components/price-estimate/PriceEstimate';
import { useEffect } from 'react';
import { LoadingOverlay } from '@/components/loading/LoadingOverlay';
import { errorType } from '../my-page/utils/errorType';
import { ErrorContent } from '../my-page/components/ErrorContent';

export const Page = styled(PageComponent)`
    > div {
        max-width: 600px;
    }
`;

export const PriceEstimateView = () => {
    const navigate = useNavigate();
    const { id } = useParams<{ id: string }>();
    const {
        data: valuation,
        isLoading,
        isError,
        error,
    } = useGetMyPageValuation(id);

    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }, []);

    return (
        <>
            {isLoading && <LoadingOverlay fixed delayMillis={0} />}
            {id && (
                <PageHeader
                    titleTextKey="priceEstimate"
                    backButtonOnClick={() =>
                        void navigate(`${PATH_MY_PAGE}/${id}`)
                    }
                    backButtonTextKey="toFrontpage"
                />
            )}
            <Page>
                {isError ? (
                    <ErrorContent errorType={errorType(error)} />
                ) : (
                    valuation?.priceEstimate && (
                        <PriceEstimate
                            priceEstimate={valuation.priceEstimate}
                        />
                    )
                )}
            </Page>
        </>
    );
};
