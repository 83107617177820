import { useParams, useNavigate } from 'react-router';
import { PATH_MY_PAGE } from '@/utils/url/routes';
import { PageHeader } from '@/components/PageHeader';
import { Page } from '@/components/Page';
import { UploadImages } from './components/UploadImages';
import { ImageGuidelines } from './components/ImageGuidelines';

export const ImagesView = () => {
    const { id } = useParams<{ id: string }>();
    const navigate = useNavigate();

    return (
        <>
            {id && (
                <PageHeader
                    titleTextKey="imagesOfCar"
                    backButtonOnClick={() =>
                        void navigate(`${PATH_MY_PAGE}/${id}`)
                    }
                    backButtonTextKey="toFrontpage"
                />
            )}
            <Page>
                <ImageGuidelines />
                <UploadImages />
            </Page>
        </>
    );
};
