import { IconButton } from '@moller/design-system';
import { PATH_SELF_DECLARATION } from '@/utils/url/routes';
import { useParams, Link } from 'react-router';
import { useGetMyPageValuation } from '../queries/useGetMyPageValuation';
import i18next from 'i18next';
import { datadogRum } from '@datadog/browser-rum';
import { useEffect } from 'react';

export const SelfDeclarationLink = () => {
    const { id } = useParams<{ id: string }>();
    const { data: valuation } = useGetMyPageValuation(id);
    const selfDeclarationIsFilledOut = !!valuation?.selfDeclaration;

    useEffect(() => {
        if (valuation && !selfDeclarationIsFilledOut) {
            datadogRum.addAction('Add self declaration card visible');
        }
    }, [valuation, selfDeclarationIsFilledOut]);

    if (valuation) {
        return (
            <IconButton
                $as={Link}
                to={`${PATH_SELF_DECLARATION}/${valuation.valuationId}`}
                label={i18next.t(
                    selfDeclarationIsFilledOut
                        ? 'seeSelfDeclaration'
                        : 'selfDeclarationCardTitle',
                )}
                icon="arrow_forward"
                showLabel="left"
            />
        );
    } else return <></>;
};
