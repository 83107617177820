import { css, styled } from 'styled-components';
import { tokens } from '@moller/gnist-themes/tokens.css.js';
import { Icon, IconVariantProvider } from '@moller/design-system';

const Wrapper = styled.div`
    margin-right: var(--moller-spacing-xs);
`;

const RadioWrapper = styled.span<{ isActive?: boolean }>`
    ${(props) => css`
        display: flex;
        box-shadow: 0 0 0  ${props.isActive ? 2 : 1}px inset;
            var(
                ${
                    props.isActive
                        ? '--moller-palette-black'
                        : '--moller-color-on-disabled'
                }
            );
        border-radius: var(--moller-border-radius-full);
        width: var(--moller-size-s);
        height: var(--moller-size-s);
        margin: var(--moller-spacing-base);
        align-items: center;
        justify-content: center;
    `}
`;

const FilledRadioElement = styled.span`
    border-radius: var(--moller-border-radius-full);
    width: var(--moller-size-xxs);
    height: var(--moller-size-xxs);
    background-color: var(--moller-palette-black);
`;

export const StepStatus = ({
    status,
}: {
    status: 'Completed' | 'Active' | 'NotStarted';
}) => {
    return (
        <Wrapper>
            {status === 'Completed' ? (
                <IconVariantProvider variant="filled">
                    <Icon icon="success" color={tokens.color.success} />
                </IconVariantProvider>
            ) : status === 'NotStarted' ? (
                <RadioWrapper />
            ) : (
                <RadioWrapper isActive>
                    <FilledRadioElement />
                </RadioWrapper>
            )}
        </Wrapper>
    );
};
